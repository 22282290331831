import { config } from "../functions/config";

export default function Strings() {
  return {
    tokenAddress: "TOKEN ADDRESS",
    logoTitle: "Logo",
    aboutTtl: "Tentang",
    wpTtl: "Whitepaper",
    allocTtl: "Alokasi Token",
    deckTtl: "Pitch Deck",
    presaleTtl: "Presale",
    rmTtl: "Peta Jalan",
    auditTtl: "Audit",
    projectTtl: "Proyek",
    quickLinksTtl: "Quick links",
    privPolicy: "Kebijakan Privasi",
    termsAndConds: "Syarat dan Ketentuan",
    amlPolicy: "Kebijakan Anti Pencucian Uang",
    sitemap: "Peta situs",
    salePolicy: `${config.tokenSymbol} Sale Syarat dan Ketentuan`,
    descText1:
      "Selamatkan dunia dari anarki dalam RPG Aksi mobile yang imersif ini! Lawan musuh dari dunia bawah kuno menggunakan mantra sihir dan senjata besi yang dahsyat! Ikuti media sosial kami untuk mendapatkan informasi terbaru!",
    descText2: "Copyright © 2022-2024 ",
    descText3:
      "Suite 305 Griffith Corporate Centre, Kingstown, St Vincent, and the Grenadines.",
    quickLinks: "Quick links",
    // presaleStage: 'Presale stage',
    presaleStage: "PUTARAN PRESALE TERAKHIR!",
    presaleEnded: "PRESALE BERAKHIR!",
    stayTuned: "Harap tetap menunggu petunjuk klaim token.",
    thankyouForParticipating:
      "TERIMA KASIH TELAH BERPARTISIPASI DALAM PRAPENJUALAN hbox!",
    // buyBeforeTheEnd: "Hurry before the end of presale!",
    buyBeforeTheEnd: "Please wait for announcements",
    buyBefore: "Nantikan pengumuman IDO. Harga peluncuran:",
    soldTtl: "TERJUAL",
    raisedTtl: "NAIK",
    connectWallet: "Connect Wallet",
    minimumPurchWarn: `Purchase amount must be not less than {VALUE1} ${config.tokenSymbol} and no more than {VALUE2} ${config.tokenSymbol}.`,
    minimumPurchWarn2: `Purchase amount must be no more than {VALUE1} ${config.tokenSymbol}.`,
    presaleInactive: "Presale is inactive.",
    insufFunds: "You have not enough money to make a transaction.",
    impossToTx: "It is impossible to make a transaction.",
    invalidTime: "Invalid time.",
    stayAndWait:
      "Please stay on the website and wait for the transaction confirmation",
    goesWrong: "You canceled the operation or something goes wrong.",
    thankYou: "Thank you for your purchase!",
    tokPayFailed: "Token payment failed",
    claimNoFunds: "You have no tokens available for withdrawal.",
    statClaimed: `Your ${config.tokenSymbol} tokens will be sent to your address soon.`,
    connectNote:
      "*Please note that, this action can not be undone once the wallet is authorize.",
    connectTheWallet: "Connect the wallet",
    youOwn: "You own",
    buyWith: "Buy with",
    claimTokens: "Claim",
    buyTokenWith: `Buy ${config.tokenSymbol} with`,
    minimumTtl: "Minimum",
    buyDesc: `Enter the amount of ${config.tokenSymbol} you wish to purchase, then click "Buy"`,
    buyTtl: "Buy",
    payTtl: "Pay",
    benefit1: "Akses eksklusif ke beta tertutup MVP",
    benefit2: "Akses ke diary pengembangan",
    benefit3: "Senjata Legendary terbatas\n di dalam game",
    benefit4: "Whitelist otomatis untuk penjualan NFT mendatang",
    benefit5: "Entry untuk undian fisik dan digital",
    benefit6: "Dan masih banyak lagi!",
    whybuyToken1:
      "MVP dapat dimainkan dan beta akan diluncurkan pada bulan Mei",
    whybuyToken2: "4 juta unduhan gabungan di Android dan iOS",
    whybuyToken3: "6 juta pendapatan untuk versi MVP",
    whybuyToken4:
      "rencana komprehensif 2 tahun untuk pengembangan game dan akuisisi pengguna",
    whybuyToken5: "Tim terdiri dari veteran pengembangan game",
    approveMessage: `Payment with stablecoins will require confirmation of two transactions: first to approve spending and second to ${config.tokenSymbol} purchase. Stay on the website until the all transactions are confirmed. Only ERC-20 tokens supported.`,
    wygTitle1: "Lebih dari 4 juta unduhan di App Store dan Playstore",
    wygTitle2: "Pendapatan lebih dari 6 juta USD dari pembelian dalam aplikasi",
    wygTitle3: "4,8 dari 5 peringkat bintang",
    wygTitle4: "Lebih dari 250 jam gameplay kampanye",
    wygTitle5: "Tersedia beberapa mode permainan asinkron",
    wygTitle6: "MVP web3 diluncurkan pada September 2024",
    wygTitle7: "Proyek dijalankan oleh veteran pengembangan game",
    wygTitle8: "Permainan silang diaktifkan untuk seluler dan PC",
    wygTitle9: "Peta PvP Multiplayer yang sangat menarik untuk 16 pemain",
    wygTitle10:
      "Nikmati pengalaman bermain game pada 60fps dengan resolusi 4k (PC)",
    modeT1: "Pemburuan Monster",
    modeT2: "Benteng Bos",
    modeT3: "PvP",
    modeT4: "Dungeon Harian",
    modeT5: "Dungeon Acara",
    modeT6: "Kampanye",
    modeInfo:
      "MVP Swords of Blood memiliki beberapa mode permainan! Dapatkan akses eksklusif ke beta tertutup (peluncuran pada bulan Mei) saat Anda berpartisipasi dalam",
    aboutDesc1: `Menawarkan pertarungan yang mencolok, grafis yang luar biasa, dan berbagai mode permainan, ${config.appName} adalah RPG hack-and-slash F2P serba cepat berkualitas AAA pertama di blockchain yang diluncurkan di Ape Chain.`,
    aboutDesc2:
      "Kekerasan, pengkhianatan, dan kejahatan yang licik telah menyebabkan kejatuhan dunia Ezura yang brutal. Pengkhianat, makhluk dengan kejahatan luar biasa, telah memusnahkan para pelindung kerajaan dan selama 1.000 tahun, kegelapan telah berkuasa",
    aboutDesc3:
      "Ini adalah masa-masa putus asa, keputusasaan, dan kematian, saat pasukan Pengkhianat menutupi negeri ini.",
    aboutDesc4:
      "Namun dari reruntuhan, kemungkinan keselamatan muncul; yang terakhir dari tatanan prajurit yang dulunya perkasa telah dibangkitkan oleh dewi kuno yang dikenal sebagai Sentinel. Bersama-sama, Anda akan mencoba membawa secercah harapan ke tanah yang sunyi.",
    aboutDesc5:
      "Jadilah penjelajah ruang bawah tanah yang tak kenal takut, penyihir pemburu hadiah, atau pejuang yang kuat, berburu harta karun dan peralatan langka. Buat perlengkapan epik, tekuk keajaiban alam sesuai keinginanmu, dan balaskan dendam saudara-saudaramu yang jatuh dalam pencarian epik kekuatan dan balas dendam.",
    contactUsDesc: `Jadilah duta ${config.appName} dan dapatkan token game, emas, dan permata.`,
    contactUs: "Contact Us",
    asSeenOnTtl: "Seperti yang terlihat pada",
    facTtl1: "Bangun kombinasi serangan yang menghancurkan",
    facTtl2: "Kuasai berbagai macam senjata dan elemen",
    facTtl3: "Bangun kombinasi serangan yang menghancurkan",
    facTtl4: "Craft unique, powerful gear and weapons",
    facTtl5: "Gunakan gameplay hack n slash untuk membunuh lawan Anda",
    facTtl6: "Compete with other players in fast-paced, brutal PVP",
    teamName1: "James Seaman",
    teamTtl1: "CEO",
    teamBio1:
      "A 36-year veteran of the video game industry who has worked on game titles, such as The Sims, Sims City, Ghost Recon, Rainbow Six, and more), on many levels - from programming to CEO.",
    teamName2: "Jeremy Brown",
    teamTtl2: "COO",
    teamBio2:
      "Worked extensively on the Sony PlayStation and Xbox marketing accounts as well as some of the most iconic game titles including Halo, Call of Duty, Assassin’s Creed and the Tom Clancy franchises.",
    teamName3: "Mariusz Szynalik",
    teamTtl3: "Project Director for HitBox Games",
    teamBio3:
      "Over 10 years of experience as a gaming executive focused on video games publishing and sales of PC/consoles and mobile Free-To-Play and blockchain-enabled games. Cofounder of Artifex Mundi S.A.",
    teamName4: "Yupeng Qin",
    teamTtl4: "Asian region development consultant",
    teamBio4:
      "Over ten years of experience in community building, marketing, public and client relations, content writing andcreation, and social media management including projects in web3, gamefi, and defi.",
    teamName5: "Jakub Szamalek",
    teamTtl5: "Narrative Director",
    teamBio5: "A decade of experience in UI/UX design",
    teamName6: "John Moyer",
    teamTtl6: "Bass Guitarist of the band Disturbed",
    teamBio6:
      "Former Director of Narrative Design for Cyberpunk and The Witcher 3",
    teamName7: "Vee Lozano",
    teamTtl7: "Business Development Lead",
    teamBio7:
      "Star Judge and world champion bladesmith on the History Channel’s hit show, Forged in Fire",
    teamName8: "Jason Hung",
    teamTtl8: "Advisor",
    teamBio8:
      "Lead writer for the upcoming megahit: Gord Author and Hollywood Screenplay Writer",
    teamName9: "Charlie Hu",
    teamTtl9: "Advisor",
    teamName10: "Adam Vine",
    teamTtl10: "Narrative Design Director",
    teamName11: "Ben Abbott",
    teamTtl11: "Weapons Creator and Consultant ",
    teamName12: "Kamran Sadikhov",
    teamTtl12: "UI/UX Designer",
    teamTtl: "Team",
    partnersTtl: "Partners",
    backersTtl: "Pendukung",
    launchPartnTtl: "Mitra Peluncuran",
    techPartnTtl: "Mitra Teknologi",
    comPartnTtl: "Mitra Komunitas",
    presTtl: "Bagaimana cara membeli",
    presStep1: "LANGKAH 1",
    presStep2: "LANGKAH 2",
    presStep3: "LANGKAH 3",
    presStep1Desc:
      "Pertama, pastikan Anda memiliki dompet MetaMask yang terinstal di browser Anda, atau gunakan salah satu dompet untuk terhubung ke salah satu dompet yang didukung (kami sarankan Trust Wallet).Pertama, pastikan Anda memiliki dompet MetaMask yang terinstal di browser Anda, atau gunakan salah satu dompet untuk terhubung ke salah satu dompet yang didukung (kami sarankan Trust Wallet).<br/>Membeli dari browser desktop akan memberikan Anda pengalaman membeli yang lebih lancar. Untuk ini kami merekomendasikan Metamask.<br/>Jika Anda membeli di ponsel, kami sarankan untuk menggunakan Trust Wallet dan terhubung melalui browser bawaan (cukup salin https://swordsofblood.com ke browser Trust Wallet).",
    presStep2Desc:
      "Setelah Anda mendapatkan penyedia dompet yang ingin Anda gunakan, klik Hubungkan Dompet dan pilih opsi yang sesuai. Untuk aplikasi dompet seluler, Anda harus memilih Wallet Connect.",
    presStep3Desc: `Anda bisa membeli ${config.tokenSymbol} dengan ERC20 ETH, USDT, USDC, dan DAI. Setelah semua tahap presale selesai, Anda akan dapat mengklaim ${config.tokenSymbol} Anda melalui halaman ini. Mohon tunggu pengumuman dari kami.`,

    date: {
      Days: "HARI",
      Hours: "JAM",
      Minutes: "MENIT",
      Seconds: "DETIK",
    },
    timerLabel: "Presale 3 berakhir pada :",
    investorTtl: "Investor Putaran Awal",
  };
}

