import { Suspense, useContext, useEffect, useRef, useState } from "react";
import Localization from "../../context/localization";
import Web3Provider from "../../context/web3provider";
import Video from "./bannerVideo";

import {
  GetStorageData,
  SetStorageData,
  getCurrentTimestamp,
  importAllImages,
  retrieveAdditionalPresaleInfo,
  retrievePresaleInfo,
} from "../../functions/common";
import { config } from "../../functions/config";
import {
  DisconnectProvider,
  GetAccount,
  SetGlobalProvider,
  getPresaleContract,
  setNetwork,
} from "../../functions/onchain";
import styles from "./banner.module.css";
import "./custom.css";

// const Video = lazy(() => import("./bannerVideo"));

export default function Banner() {
  const [refresh, setRefresh] = useState(0);
  const { strings } = useContext(Localization);
  const web3provider = useContext(Web3Provider);
  const [inLoading, setInLoading] = useState(true);
  const [presaleData, setPresaleData] = useState(null);
  const [curStep, setCurStep] = useState(0);
  // const [loadingInfoText, setLoadingInfoText] = useState(null);
  // const [showConnectModal, setShowConnectModal] = useState(false);
  // const [showBuyModal, setShowBuyModal] = useState(false);
  // const [purchaseToken, setPurchaseToken] = useState(4);
  // const [hideConnectButton, setHideConnectButton] = useState(false);
  const curStepRef = useRef(0);
  const progressLine = useRef(null);
  const inFetch = useRef(false);
  const images = importAllImages();

  const get_percent = (
    totalTokensSold,
    stageTotalTokensTarget,
    totalUSDraised,
    stageTotalUSDTarget
  ) => {
    const percent_tokens = Math.round(
      (totalTokensSold / stageTotalTokensTarget) * 100
    );
    const percent_raised = Math.round(
      (totalUSDraised / stageTotalUSDTarget) * 100
    );
    return Math.round((percent_raised + percent_tokens) / 2);
  };

  const getPrepresaleData = async (mode = 0) => {
    if (mode > 0 && !web3provider.current.status) {
      Disconnect();
      return false;
    }

    try {
      setInLoading(true);

      let info = {},
        getSC = true;

      if (presaleData) info = presaleData;
      else {
        const storedPresaleData = GetStorageData("storedPresaleData");
        if (storedPresaleData) {
          const decData = JSON.parse(storedPresaleData);
          if (!decData.ldt || getCurrentTimestamp() - decData.ldt < 3600) {
            Object.assign(info, decData);
            getSC = false;
          }
        }
      }
      if (mode > 0) {
        info.wallet = await GetAccount(web3provider.current.instance);
        if (!info.wallet) {
          Disconnect();
          return false;
        }
      }

      if (mode === 0) {
        web3provider.current.network = setNetwork(
          process.env.NODE_ENV === "development" ? "BSC" : "ETHEREUM"
        );
        await SetGlobalProvider(2, web3provider);
      }

      let contract = getPresaleContract(web3provider.current.instance);
      if ((mode === 0 || mode === 2) && getSC) {
        inFetch.current = true;
        Object.assign(info, await retrievePresaleInfo(contract));
        SetStorageData(
          "storedPresaleData",
          JSON.stringify(Object.assign(info, { ldt: getCurrentTimestamp() }))
        );
        inFetch.current = false;
      }

      if (mode >= 1)
        Object.assign(
          info,
          await retrieveAdditionalPresaleInfo(contract, info)
        );

      // check the total token sold if it's heigh leave it as it is or add the static price
      if (info.totalTokensSold + 27500000 <= 32500000) {
        info.totalTokensSold += 27500000;
        info.totalUSDraised += 1100000;
      }
      info.stageTotalTokensTarget = 32500000;
      info.stageTotalUSDTarget = 1370000;

      // get the precent from the total usd and token
      info.stagePercentsCompleted = get_percent(
        info.totalTokensSold,
        info.stageTotalTokensTarget,
        info.totalUSDraised,
        info.stageTotalUSDTarget
      );
      setPresaleData(info);

      if (mode > 0) setCurStep(1);
      setInLoading(false);
    } catch (err) {
      setInLoading(false);
    }
  };

  const Disconnect = () => {
    DisconnectProvider(web3provider);
    setCurStep(0);
    setInLoading(false);
  };

  useEffect(() => {
    curStepRef.current = curStep;
  }, [curStep]);

  useEffect(() => {
    if (window && window.ethereum) {
      window.ethereum.on("chainChanged", (chainIdHex) => {
        if (chainIdHex !== config[web3provider.current.network].chainIdHex)
          Disconnect();
      });

      window.ethereum.on("accountsChanged", async () => {
        if (
          !web3provider.current.status ||
          !(await GetAccount(web3provider.current.instance))
        )
          Disconnect();
        else if (curStepRef.current === 1) await getPrepresaleData(2);
      });
    }

    getPrepresaleData(0);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      web3provider.current.status = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const specificImage = images["home/banner/presale-banner.webp"];

  console.log(specificImage);

  return (
    <>
      <div className={["mainBanner", "timer", styles.banner].join(" ")}>
        <div
          style={{ justifyContent: "flex-start" }}
          className={styles.heroSection}
        >
          <Suspense
            fallback={
              <img
                src={specificImage}
                alt="Play-to-earn is out. Earn real rewards with Swords of Blood's play-to-own and play-to-win model where the gaming experience is the priority."
                className={["image", styles.image].join(" ")}
              />
            }
          >
            <Video muted />
          </Suspense>

          <div className={styles.cardOverlay}>
            <div className={styles.cardContent}>
              <div className="s_card">
                <div className="s_container">
                  <h1 className="s_headline">New experience coming</h1>
                  <div className="s_button-container">
                    <button
                      onClick={() =>
                        window.open(
                          "https://forms.gle/yDDSmeDRhqg1QBAB9",
                          "_blank"
                        )
                      }
                      className="s_sign-up-button"
                    >
                     Beta Sign Up
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>
  {`
  .s_card {
    border-radius: 10px;
    max-width: 350px;
    width: 100%;
    position: relative;
    z-index: 100;
    height: 100%;
  }

  /* Header styles */
  .s_card-header {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
  }

  /* List styles */
  .s_card-list {
    list-style-type: none;
    padding: 0;
    margin: 20px 0;
  }

  .s_card-list li {
    margin: 25px 0;
    padding-left: 20px;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    position: relative;
  }

  .s_card-list li::before {
    content: url("https://tuk-cdn.s3.amazonaws.com/can-uploader/swords-dino.png");
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translateY(-50%) scale(0.3);
  }

  /* Button styles */
  .s_sign-up-button {
    background-image: url("https://tuk-cdn.s3.amazonaws.com/can-uploader/swords_btn.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    color: #c9ab84;
    padding: 0 20px;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    transition: 0.3s;
    width: 300px;
    height: 60px;
    border: none;
    z-index: 10;
    position: relative;
  }

  .s_sign-up-button:hover {
    opacity: 0.7;
  }

  .s_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    max-width: 350px;
    gap: 10%;
    height: 100%;
  }

  .s_headline {
    text-align: right;
    font-size: 80px;
    display: block;
    text-transform: uppercase;
  }

  .s_button-container {
    text-align: right;
  }

  .top_eye {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  .bottom_eye {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  /* Media Queries */
  @media (max-width: 768px) {
    .s_card {
      padding: 15px 15px 48px;
    }

    .s_card-list li {
      margin: 25px 0;
      padding-left: 25px;
      font-size: 14px;
    }

    .s_card-list li::before {
      transform: translateY(-50%) scale(0.2);
      left: -20px;
    }

     .s_sign-up-button {
   
    width: 248px;
    height: 35px;
 
  }

      .s_container {
   justify-content: flex-end;
    max-width: 350px;
    gap: 0%;
  }
    
    .s_headline {
      font-size: 40px;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .s_card-list li {
      margin: 20px 0;
    }

  }
  `}
</style>

    </>
  );
}
