import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Localization from "../../context/localization";
import { importAllImages } from "../../functions/common";
import { config } from "../../functions/config";
import { socialLinks } from "../social-links";
import styles from "./sidebar.module.css";

export default function Sidebar({ showSidebar, setShowSidebar }) {
  const { strings, lang, locales, SetLanguage } = useContext(Localization);
  const images = importAllImages();

  const [langMenu, setLangMenu] = useState([]);
  const langSwitch = useRef(null);

  const imageIconsPath = (name) => {
    return images[`sidebar-icons/${name}.png`];
  };
  const [projectDropDown, setProjectDropDown] = useState(false);
  const [aboutDropDown, setAboutDropDown] = useState(false);
  let toggleHere = () => {
    let closeIco = document.getElementById("close-ico");
    let openIco = document.getElementById("open-ico");
    closeIco.classList.add("no-open");
    openIco.classList.add("imp-mbl");
  };

  useEffect(() => {
    let langs = [];
    locales.forEach((l) => {
      if (l !== lang) {
        langs.push(
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            key={l}
            to="#"
            className={styles.content}
            onClick={(_e) => {
              SetLanguage(l);
            }}
          >
            <img
              src={images[`lang/${l}.png`]}
              alt="language"
              className={styles.flag}
            />{" "}
            {l.toUpperCase()}
          </Link>
        );
      }
    });
    setLangMenu(langs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <div
      className={[styles.sidebar, showSidebar ? styles.showSidebar : ""].join(
        " "
      )}
    >
      <div>
        <NavLink
          to="/"
          onClick={() => {
            setProjectDropDown(false);
            setAboutDropDown(false);
          }}
          className={styles.logoContainer}
        >
          <img
            src={images["logo.webp"]}
            alt={strings.logoTitle}
            className={styles.logo}
          />
        </NavLink>

        {/* // -- */}
        {/* <NavLink to="/signup">
          <button className={styles.betaSignUpButton}>
            <img
              src={images["Union.svg"]}
              alt="Dragon Icon"
              className={styles.dragonIcon}
            />
            BETA SIGN UP
          </button>
        </NavLink> */}
        <div className={styles.adressAndLanugage}>
          <div className={styles.languageChangingDropdown}>
            <button ref={langSwitch} className={styles.dropbtn}>
              <div className={styles.languageAndCountryName}>
                <span className={styles.iconAndCountry}>
                  <img
                    src={images[`lang/${lang}.png`]}
                    alt="language"
                    className={styles.flag}
                  />{" "}
                  {lang.toUpperCase()}
                </span>
                <FontAwesomeIcon icon={faAngleDown} />
              </div>
            </button>
            <div className={styles.dropdownContent}>
              {langMenu.length > 0 && <>{langMenu}</>}
            </div>
          </div>
        </div>
        {/* //  */}
      </div>
      <div
        onClick={() => {
          setProjectDropDown(false);
          setAboutDropDown(false);
        }}
      >
        <div className={styles.quicklinksContainer}>
          {/* <h6 className={styles.quichLinksHeader}>{strings.quickLinksTtl}</h6> */}
          <a
            href="/assets/aml-hitbox.pdf"
            target="_blank"
            className={styles.link}
          >
            {strings.amlPolicy}
          </a>
        </div>
        {/* <div className={styles.socialMediaContainer}>
          <div className={styles.allSocialMedia}>
            {socialLinks.map((p, i) => {
              return (
                <a
                  key={i}
                  href={p.link}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.socialIcon}
                >
                  <img src={p.img} alt="" className={styles.socials} />
                </a>
              );
            })}
          </div>
        </div> */}
      </div>
    </div>
  );
}
